import { type RefObject, useCallback, useEffect, useState } from 'react';

import { $config } from '@client/core/atoms/config.js';
import { updateMetricByKey } from '@client/core/atoms/metrics.js';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements.js';
import { useInScreen } from '@client/core/hooks/index.js';
import {
  forceBatchRequestPlacements,
  type InViewSettings
} from '@client/core/utils/getInViewSettingsByPlacementId.js';
import {
  type AdnAdUnit,
  type AdnConfig,
  type AdnPlacement,
  type ClientAdPlacement,
  debugLog,
  PlacementId,
  PlacementStatusesEnum
} from '@schibsted-nmp/advertising-shared';
import type { AdnAdUnitProps } from '@client/adnuntius/AdUnit/AdnAdUnit.js';
import { getAllFeatureStatuses } from '@client/core/atoms/unleashFeatures.js';
import { $adnTargetingAtom } from '@client/core/atoms/adnTargeting.js';

import { getDebouncedLoadAdFunction } from '../batch.js';

export function useInitiateAdnUnit(
  props: AdnAdUnitProps & { ref: RefObject<HTMLDivElement> }
) {
  const [placementIsInView, setPlacementIsInView] = useState(false);

  const { adServer } = $config.get();
  const adnConfig = adServer.adn as AdnConfig;
  const { placement, inViewSettings } = props;
  const { placementId, status } = placement;

  const { isIntersecting: isInView } = useShouldLoadAd({
    placementId,
    ref: props.ref,
    inViewSettings
  });
  const [slotState, setSlotState] = useState<AdnAdUnit | null>(null);

  /**
   * This loads the ads in a batch
   */
  const sendToRequestBatchAds = useCallback((adUnit: AdnAdUnit | null) => {
    if (adUnit) {
      refreshKeyValuesOnAdnAdUnit(adUnit);
      getDebouncedLoadAdFunction()(adUnit);
    }
  }, []);

  // Some placements need to wait for a delay before they are in view, because
  // of pagination trouble etc
  useEffect(() => {
    if (status === 'refresh') {
      // Wait for ads that are currently in view
      if (!isInView) {
        updatePlacementKeyValueById(placementId, 'status', 'pending');
        setPlacementIsInView(isInView);
      }
    } else {
      setPlacementIsInView(isInView);
    }
  }, [isInView, status, placementId]);

  useEffect(() => {
    // forceBatchRequest is a special case where we want to load the ads in a batch, such as the horseshoe placements (left,right,top,wallpaper)
    // If that happens, completely ignore inView and just send them straight to request
    const isPendingInViewOrForceBatch =
      (placementIsInView && status === 'pending') ||
      status === 'forceBatchRequest';
    if (slotState && isPendingInViewOrForceBatch) {
      updatePlacementKeyValueById(placementId, 'status', 'request');
      sendToRequestBatchAds(slotState);
    }
  }, [slotState, placementIsInView, placementId, status]);

  const getAdUnit = (placement: ClientAdPlacement<AdnPlacement>) => {
    const { targetId, auId } = placement.adServer?.config || {};
    const kv = $adnTargetingAtom.get();

    const { enableAdnTestCampaign } = getAllFeatureStatuses();

    if (enableAdnTestCampaign) {
      const hasAdnTestCampaign = kv.some((item) => item['adnTestCampaign']);
      if (!hasAdnTestCampaign) {
        kv.push({ adnTestCampaign: ['true'] });
      }
    }

    return {
      auId,
      targetId,
      kv
    } as AdnAdUnit;
  };
  /**
   * Set up the ad
   */
  useEffect(() => {
    if (placementId && !slotState) {
      debugLog('Defining Slot for placement: ', placementId);

      const adUnit = getAdUnit(placement);

      if (adUnit) {
        setSlotState(adUnit);
        updateMetricByKey(placementId, PlacementStatusesEnum.SlotCreated);
        debugLog('Adn successfully set-up for placement:', placementId);

        // Add event listeners for ad events
      }
    }
  }, [placement, adnConfig, placementId, getAdUnit, slotState]);

  return { placement };
}

function useShouldLoadAd({
  placementId,
  ref,
  inViewSettings
}: {
  placementId: PlacementId;
  ref: RefObject<HTMLDivElement>;
  inViewSettings: InViewSettings | undefined;
}) {
  const effectiveInViewSettings = forceBatchRequestPlacements.includes(
    placementId
  )
    ? null
    : inViewSettings;

  const { isIntersecting } = useInScreen({
    ref,
    ...(effectiveInViewSettings || {})
  });

  return {
    isIntersecting: isIntersecting || !effectiveInViewSettings
  };
}

function refreshKeyValuesOnAdnAdUnit(adUnit: AdnAdUnit) {
  adUnit.kv = $adnTargetingAtom.get();
}
